import React from 'react';
import { connect } from 'react-redux';
import { StaticPage, TopbarContainer } from '..';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  ContractsInfoContent,
  NamedRedirect,
} from '../../components';
import { ensureCurrentUser } from '../../util/data';
import { LoadingPage } from '../LoadingPage/LoadingPage';

import css from './ContractsInfoPage.module.css';

// if not logged in redirect user to contracts page (signup for contracts)
const ContractsInfoPage = ({ user, userLoading }) => {
  if (userLoading) return <LoadingPage />;

  const currentUser = ensureCurrentUser(user);

  const types = currentUser?.attributes?.profile?.privateData?.userTypes || [];

  // if logged in and not contract customer redirect to landing page
  if (!types.includes('contractCustomer')) {
    return <NamedRedirect name="LandingPage" />;
  }

  let link = types.includes('liverpoolPlace' || 'liverpoolCCG')
    ? 'https://training.signalise.coop?th__ug=ab87fa18'
    : types.includes('knowsleyPlace' || 'knowsleyCCG')
    ? 'https://training.signalise.coop?th__ug=2513513f'
    : types.includes('seftonPlace' || 'seftonSFCCG') || types.includes('southportFormbyPlace')
    ? 'https://training.signalise.coop?th__ug=7b2410ba'
    : types.includes('wirralCHC')
    ? 'https://training.signalise.coop?th__ug=4c4bea2f'
    : types.includes('alderHey')
    ? 'https://training.signalise.coop?th__ug=4af91a67'
    : types.includes('luhft')
    ? 'https://training.signalise.coop?th__ug=68c0b20f'
    : types.includes('lhch')
    ? 'https://training.signalise.coop/courses/using-our-services-introduction?th__ug=ad9997d3'
    : types.includes('lwft')
    ? 'https://training.signalise.coop/?th__ug=20e7ead3'
    : types.includes('bchft')
    ? 'https://training.signalise.coop/?th__ug=873c8dbc'
    : types.includes('mcft')
    ? 'https://training.signalise.coop/?th__ug=7551afa8'
    : types.includes('waltonCentre')
    ? 'https://training.signalise.coop/?th__ug=6df26e88'
    : types.includes('clatterbridge')
    ? 'https://training.signalise.coop/?th__ug=6e7a766f'
    : null;

  return (
    <StaticPage className={css.root} title="Contracts Info Page">
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <ContractsInfoContent link={link} />
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

const mapStateToProps = state => ({
  user: state.user.currentUser,
  userLoading: state.user.currentUserLoading,
});

export default connect(mapStateToProps, null)(ContractsInfoPage);
